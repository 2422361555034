/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React, {
    useEffect, useRef,
} from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { useQuery, gql } from '@apollo/client';

import { withStyles } from '@material-ui/core/styles';
import {
    object, string, shape, bool, arrayOf, number, array, func, oneOfType,
} from 'prop-types';
import mbpLogger from 'mbp-logger';

// product container ui components
import SimpleProductSkeleton from '../../../GraphqlSkeletonComponents/SimpleProductSkeleton';
import GraphqlProductContainerBody from './GraphqlProductContainerBody';
import GraphqlBestSellerCollection from '../../../Graphql404Error/Graphql404ErrorProductContainer';

// helpers, redux, hooks
import { GRAPHQL_ENV } from '../../../../../gql';
import { getUserSubmittedProductFilterZipcode, getLocationType, getIsBot } from '../../../../../../state/ducks/App/App-Selectors';
import {
    setUserSubmittedProductFilterZipcode,
    setLocationType,
} from '../../../../../../state/ducks/App/App-Actions';
import { getBrand } from '../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getBestSellersId, getFeatureFlag, getPresentationFamily } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { usePrev } from '../../../../../helpers/hooks/usePrev';
import { trackData } from '../../../../../helpers/tracking/common/commonTrackingHelpers';
import { trackEvent } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getActiveABTests, getActiveABTest } from '../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import priceRangeFilter from '../../../../../helpers/priceRangeFilter';
import getProductLayout from '../../../../../helpers/getProductLayout';
import useSSRMediaQuery from '../../../../../helpers/hooks/useSSRMediaQuery';
import findContent from '../../../../../gql/queries/findContent';
import useDynamicRankImpressionEvent from '../../../../../helpers/hooks/useDynamicRankImpressionEvent';
import useExperimentServiceAttributes from '../../../../../helpers/experimentService/useExperimentServiceAttributes';

/* eslint-disable */
const styles = (theme) => ({
    noProductsText: {
        color: theme.palette.noProductsTextColor || '#EC0909',
        margin: '16px 0',
        fontSize: '14px',
        textAlign: 'center',
    },
    bestSellerText: {
        color: theme.palette.common.black,
        margin: '15px',
        fontSize: '18px',
        textAlign: 'center',
    },
});

const getCategoryProductQuery = (targetingEnabled) => (gql`
query CategoryProductQuery($productOptions: ProductOptions, $brand: String!, $environment: String!, $id: String!, $locale: String!, $zipCode: String!, $locationType: String!${targetingEnabled ? ', $targeting: [Targeting]' : '' }) {
    findCategoryById(brand: $brand, environment: $environment, id: $id, locale: $locale, productOptions: $productOptions, zipCode: $zipCode, locationType: $locationType${targetingEnabled ? ', targeting: $targeting' : '' }) {
        products {
            id
            brandId
            is3dEnabled
        image {
            additionalImages
            altText
            name
            path
            snipe
            snipeImagePath
            snipeProductEndDate
            snipeCategoryEndDate
            snipeProductStartDate
            snipeCategoryStartDate
            collectionImage
            __typename
        }
        productType
        reviews
        partNumber
        baseCode
        prices {
            currency
            type
            value
            __typename
        }
        skuPriceRange {
            sale {
                value
            }
            retail {
                value
            }
            __typename
        }
        name
        brand
        seo {
            url
            __typename

        }
        productSkus {
            marketplaceShopName
            partNumber
            id
            __typename
          }
        isPassportEligible
        isPersonalizable
        availability {
            availabilityIndicator
            deliveryDateType
            displayEndDate
            displayStartDate
            earliestShipDate
            latestShipDate
            perishable
            productDeliveryType
            shipAlone
            deliveryMessage
            __typename
        }
        __typename
    }
    totalProducts
    __typename
}
}
`);

const defaultPageSize = 24;

const determineProductOptions = ({ lowToHighBtn, highToLowBtn }, facetsData, priceRangeData, zipCode, locationType, pageNumber, isBot, categoryData, activeABTests, pageSize = defaultPageSize, isAltProductRankEnabled, isFacetFilterPriceEnabled) => {
    const abTestsEnabled = isAltProductRankEnabled && categoryData.content?.entries?.[0]?.is_alt_rank_enabled;
    const modelInfo = abTestsEnabled && (activeABTests?.model_names?.find((model) => model.id === categoryData?.category?.id) || { name: activeABTests?.model_names?.[0]?.name, orderBy: activeABTests?.model_names?.[0]?.orderBy });
    const orderBy = modelInfo?.orderBy || 'BEST_SELLERS';
    let modelName = modelInfo?.name || '';

    // Exclusion experience
    const isCategoryExcluded = activeABTests?.Exclusion?.every((model) => model?.id !== categoryData?.category?.id);
    if (isCategoryExcluded && activeABTests?.rank_model_name) {
        modelName = activeABTests?.rank_model_name
    }

    let productOptions = {
        orderBy,
        modelName,
        locationType,
        page: pageNumber,
        pageSize,
    };
    if (zipCode !== '' && !facetsData.length) {
        if (highToLowBtn) {
            return {
                orderBy: 'PRICE_DESC', zipCode, locationType, page: pageNumber, pageSize,
            };
        } if (lowToHighBtn) {
            return {
                orderBy: 'PRICE_ASC', zipCode, locationType, page: pageNumber, pageSize,
            };
        }
        return {
            orderBy, modelName, zipCode, locationType, page: pageNumber, pageSize,
        };
    }
    if (highToLowBtn) {
        productOptions = {
            orderBy: 'PRICE_DESC', locationType, page: pageNumber, pageSize,
        };
    } if (lowToHighBtn) {
        productOptions = {
            orderBy: 'PRICE_ASC', locationType, page: pageNumber, pageSize,
        };
    }
    if (facetsData.length) {
        productOptions = {
            page: pageNumber, pageSize, orderBy: productOptions.orderBy, zipCode, locationType,
        };
        let priceRanges = [];
        let newFacetsData = facetsData;
        if (isFacetFilterPriceEnabled) {
            // first we checked that value is present or not
            if (typeof priceRangeData !== 'undefined'  &&  priceRangeData?.entries?.length > 0) {
                newFacetsData = [];
                const priceRangeFilterData = priceRangeFilter(facetsData, priceRangeData);
                newFacetsData = priceRangeFilterData.newFacetsData;
                priceRanges = priceRangeFilterData.priceRanges;
            }
            if (priceRanges.length > 0) {
                productOptions.priceRangeFilter = {
                    fieldName: 'all_prices',
                    ranges: priceRanges,
                };
            }
        }
        if (newFacetsData.length > 0) {
            productOptions.facets = newFacetsData;
        }
    }
    return productOptions;
};

const GraphqlProductContainer = ({
    brand, classes, categoryData, userSubmittedZip:userZip, locationType, breadCrumbArray, page, filterData: {
        giftBox, localFlorist, lowToHighBtn, highToLowBtn, sellingBtn,
    }, isBot, path, skipAddons, initialProductData, filterData, facetsData, location, setFilterZip, setLocation, bestSellersId, showMovieUpsell, inCollectionBannerData, inCollectionBannerV2Data, track,  activeABTests, priceRangeData, zipCodeUnavailableProducts,
    modelNames, leftRail, isDesktopFacetsV3, pullProductCount, isGnavEnabled, isCategoryPage
}) => {

    const findContentOptions = {
        brand: brand['domain-name'],
        contentType: 'brand_configuration',
    };
    const GET_BRAND_CONFIG = findContent(findContentOptions);

    // Query will pick data from cache
    const { data:brand_data} = useQuery(GET_BRAND_CONFIG);

    const brandPageSize = brand_data?.findContent?.content?.entries?.[0]?.other?.products_load_more?.no_of_initial_products || null
    const categoryPageSize = categoryData?.content?.entries?.[0]?.page_size || {}

    let defaultBrandPageSize = {
        mobile:  brandPageSize || null,
        desktop: brandPageSize || null,
    }

    if (brandPageSize && brandPageSize?.toString?.() && brandPageSize?.toString?.().indexOf('.') >= 0) {
        const splitVals = brandPageSize.toFixed?.(2)?.toString?.()?.split?.('.');
        defaultBrandPageSize = {
            desktop: splitVals?.[0] || null,
            mobile: splitVals?.[1] || splitVals?.[0] || null,
        }
    }

    const loadMoreItems = {
        mobile: categoryPageSize?.number_of_products_to_load_on_mobile || defaultBrandPageSize?.mobile || 24,
        desktop: categoryPageSize?.number_of_products_to_load_on_desktop || defaultBrandPageSize?.desktop || 36,
    }

    const ffLoadMoreEnabled = useSelector(getFeatureFlag('is-load-more-enabled'));
    const isAltProductRankEnabled = useSelector(getFeatureFlag('is-alt-product-rank-enabled'));
    const isFacetFilterPriceEnabled = useSelector(getFeatureFlag('is-facet-filter-price-enabled'));
    const isCollectionProductCountEnabled = useSelector(getFeatureFlag('is-collection-product-count-enabled'));
    const noZipForNonGnavCollection = useSelector(getFeatureFlag('is-no-zip-for-non-gnav-collections-enabled'));
    const presentationFamily = useSelector(getPresentationFamily);
    const device = useSSRMediaQuery();
    const pageItemsSize = device === 'mobile' ? loadMoreItems?.mobile : loadMoreItems?.desktop

    useDynamicRankImpressionEvent(categoryData)

    const loadMoreCount = useRef(1);
    const pageCount = useRef(1);
    const prevPath = usePrev(path);
    const mountedPageSizeCheck = useRef(false);
    let userSubmittedZip = userZip;
    if (noZipForNonGnavCollection && !isGnavEnabled){
        userSubmittedZip = ''; // removing zip from product query if collection doesn't support gnav
    }
    let productPageSize = pageItemsSize || defaultPageSize;
    if (activeABTests?.categoryPageSizeTest === 'VARIANT') {
        const cmsPageLayout = getProductLayout(categoryData.content);

        if (cmsPageLayout.page_size) {
            productPageSize = cmsPageLayout.page_size;
        }
    }


    // this is use for when I click on the load more button.
    // I will show the next LOAD MORE button after the next 36 products,
    // but I load 12, 12, 12 products three times on scroll down.

    const onLoadMoreClick = (productsToLoad = productPageSize || 12, isTrue = false) => {
        if(isTrue){
        loadMoreCount.current += 1;
        }
        determineQuery(productsToLoad);
    }

    if (isBot) {
        const {
            loading, data, error,
        } = useQuery(getCategoryProductQuery(false), {
            variables: {
                productOptions: {
                    page: 1, pageSize: 500,
                },
                brand: brand.domain,
                environment: GRAPHQL_ENV,
                id: categoryData?.category?.id,
                locale: 'en-us',
                locationType: 'Residence',
                zipCode: ''
            },
        });
        if (loading) {
            return <SimpleProductSkeleton />;
        }

        if (error) {
            return null;
        }
        if (data) {
            return (
                <>
                    <GraphqlProductContainerBody
                        page={page}
                        data={data?.findCategoryById?.products || []}
                        totalProducts={data?.findCategoryById?.totalProducts || 0}
                        categoryData={categoryData}
                        path={path}
                        location={location}
                        breadCrumbArray={breadCrumbArray}
                        skipAddons={skipAddons}
                        loadMoreCount={loadMoreCount.current}
                        showMovieUpsell={showMovieUpsell}
                        filterData={{ giftBox, localFlorist, options: { lowToHighBtn, highToLowBtn, sellingBtn } }}
                        pageNumber={pageCount.current}
                        productPageSize={productPageSize}
                        onLoadMore={() => () => {}}
                        loader={false}
                        inCollectionBannerData={inCollectionBannerData}
                        inCollectionBannerV2Data={inCollectionBannerV2Data}
                        leftRail={leftRail}
                        isCategoryPage={isCategoryPage}
                    />
                </>
            );
        }
    }
    /**
     * Reason added 1 for page number is, previously we were using useLazyQuery and that was only fetch data when we called the function
     * but after change it to useQuery it will re run on component re render and it was picking pageCount.current 2 because it's setting
     * in useEffect!
     */

    const {targeting, context, isGraphqlTargetingEnabled } = useExperimentServiceAttributes({queryName: 'findCategoryById'});

    const {
        loading, error, data, fetchMore, refetch, variables
    } = useQuery(getCategoryProductQuery(isGraphqlTargetingEnabled), {
        variables: {
            productOptions: determineProductOptions(filterData, facetsData, priceRangeData, userSubmittedZip, locationType, 1, isBot, categoryData, activeABTests, productPageSize, isAltProductRankEnabled, isFacetFilterPriceEnabled),
            brand: brand.domain,
            environment: GRAPHQL_ENV,
            id: categoryData?.category?.id,
            locale: 'en-us',
            zipCode: userSubmittedZip,
            locationType,
            ...(isGraphqlTargetingEnabled ? { targeting } : {}),
        },
        context,
    });

    useEffect(() => {
        if (mountedPageSizeCheck.current) {
            return;
        }

        if (activeABTests?.categoryPageSizeTest !== 'VARIANT') {
            return;
        }

        if (!data || !data?.findCategoryById?.products?.length) {
            return;
        }

        // we only want to do this check once at the appropriate time
        mountedPageSizeCheck.current = true;

        // only call refetch if the page size differs from what we got (prevent refetch of default size)
        if (productPageSize !== data?.findCategoryById?.products?.length) {
            refetch();
        }
    }, [data, refetch, activeABTests, mountedPageSizeCheck.current]);

    const isMounted = useRef(false); // see useEffect comment for explination of this useRef

    useEffect(() => {
        // when this runs on component mount run the lazy query which returns refetch
        // subsequently when this runs on filter props changing use the refetch query
        if (isMounted.current && typeof refetch !== 'undefined') {
            pageCount.current = 1;
            refetch({
                productOptions: determineProductOptions(filterData, facetsData, priceRangeData, userSubmittedZip, locationType, pageCount.current, isBot, categoryData, activeABTests, productPageSize, isAltProductRankEnabled, isFacetFilterPriceEnabled),
                zipCode: userSubmittedZip,
                locationType,
            });
            pageCount.current = 2;
        } else {
            isMounted.current = true;
        }
    }, [locationType, userSubmittedZip, lowToHighBtn, highToLowBtn, sellingBtn, facetsData?.length, modelNames]);

    useEffect(() => {
        // this useEffect is to prevent a bug where the user goes to a category page and paginates
        // then goes to a different category page and paginates
        // then goes back to the first page.
        // Before this fix users would see the second page products when they returned to the first page.
        // This was because the results of findCategoryByUrl (in GraphqlCategoryBuilder)
        // were cached when returning to the first page, and so there was no loading state in cateogrybuilder
        // and so this component never unmounts. Because it never unmounts the data returned from the lazy query on the second page still existed
        // and was displaying instead of the first page products.
        // the fix is to run this query automatically if going to a category page the user has already visited
        // there is no performance degredation because the results of this query will already be cached.
        if (path !== prevPath && (prevPath !== undefined || !facetsData?.length)) {
            pageCount.current = 1;
            refetch({
                productOptions: determineProductOptions(filterData, facetsData, priceRangeData, userSubmittedZip, locationType, pageCount.current, isBot, categoryData, activeABTests, productPageSize, isAltProductRankEnabled, isFacetFilterPriceEnabled),
                brand: brand.domain,
                environment: GRAPHQL_ENV,
                id: categoryData?.category?.id,
                locale: 'en-us',
                zipCode: userSubmittedZip,
                locationType,
            });
            pageCount.current = 2;
        }
    }, [path]);

    const noProductTexts = 'There are currently no products available in this collection. Try your search again using the search box at the top of the page, or select from our best sellers below.';

    useEffect(() => {
        if (data?.findCategoryById?.totalProducts === 0 && initialProductData.length === 0)  {
            track({
                eventCategory: 'Collection Page',
                queued: true,
                eventAction: noProductTexts,
                eventLabel: `${categoryData?.category?.name || ''}${userSubmittedZip ? ` - ${userSubmittedZip}` : ''}`,
            });
        }
    }, [path, data?.findCategoryById?.products?.length]);

    // Track the number of category products available
    trackData({
        action: 'track_category_data',
        totalCategoryProducts: data?.findCategoryById?.totalProducts || 0,
    });

    useEffect(() => {
        // do we need to wrap in FF
        if (isCollectionProductCountEnabled ) {
            pullProductCount(data?.findCategoryById?.totalProducts);
        }
    }, [data?.findCategoryById?.totalProducts])

    const determineQuery = (productsToLoad = 12) => {
        // the below condiitonal is used to mathematically determine the current page.
        // it is necessary to do this because of apollo's caching.
        // if the user goes to a category and paginates then clicks on a product and then goes back to that category
        // then they scroll to paginate again apollo will return all of the previously fetched products as part of that first
        // product query because they are all in apollo's cache.
        // this can lead to an issue where the pageCount is 3 becasue it's after the first pagination call but apollo is returning
        // more products than the first three pages. To prevent this the actual page number is calculated based on number of
        // products that are currently displayed
        if (data?.findCategoryById?.products?.length) {
            pageCount.current = Math.ceil(data?.findCategoryById?.products?.length / productsToLoad) + 1;
        } else {
            pageCount.current += 1;
        }
        if (typeof fetchMore === 'function') {
            fetchMore({
                variables: {
                    productOptions: determineProductOptions(filterData, facetsData, priceRangeData, userSubmittedZip, locationType, pageCount.current, isBot, categoryData, activeABTests, productsToLoad, isAltProductRankEnabled, isFacetFilterPriceEnabled),
                    brand: brand.domain,
                    environment: GRAPHQL_ENV,
                    id: categoryData?.category?.id,
                    locale: 'en-us',
                    zipCode: userSubmittedZip,
                    locationType,
                },
            });
        }
    };


    const isAltRankCmsFlag = categoryData?.content?.entries[0]?.is_alt_rank_enabled;
    if (
        (loading && !data)
        || (isAltProductRankEnabled && isAltRankCmsFlag && (!activeABTests?.loaded && !activeABTests?.timeout)
        )
    ) {
        return <SimpleProductSkeleton />;
    }

    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'GraphqlProductContainer.js',
            message: 'Error loading cat data from Aggregator',
            error: error,
            categoryId: categoryData?.category?.id,
            zipcode: userSubmittedZip,
            locationType: locationType,
        });
        return null;
    }

    /**
     * @description Products not available for current zip
     * - All other flows and channels display and message and best sellers collection
     */
    const getImocData = categoryData?.content?.entries?.[0]?.category_blocks?.[0]?.imoc;

    const noZipProductText = zipCodeUnavailableProducts?.copy || 'Unfortunately, the products in this collection are not available in the selected zip code. Please view our trending products and gifts below than can be delivered soon.';
    const noProductMessage = userSubmittedZip ? noZipProductText : noProductTexts;
    const collectionId = zipCodeUnavailableProducts?.collection_id || bestSellersId;
    if ((!data || !data?.findCategoryById?.products?.length) && refetch) {
        // check for refetch to make sure the query has run before deciding to render no products category
        // All other flows and channels
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: getCategoryProductQuery(isGraphqlTargetingEnabled),
            component: 'GraphqlProductContainer.js',
            message: 'No data returned for query',
            env: GRAPHQL_ENV,
        });
        return (
            <>
                {getImocData
                    ? (
                        <p className={classes.noProductsText} style={{ color: zipCodeUnavailableProducts?.copy_color }}>
                            <strong>{noProductMessage}</strong>
                        </p>
                    ) : <h1 aria-hidden="true" className={classes.noProductsText}>{noProductMessage}</h1>}
                <div aria-hidden="true" className={classes.bestSellerText}><strong>{`${zipCodeUnavailableProducts?.title || 'Here are some of our best sellers...'}`}</strong></div>
                <GraphqlBestSellerCollection brand={brand} categoryCode={collectionId} productsPerRow={zipCodeUnavailableProducts?.products_per_row || 4} />
            </>
        );
    }

    return (
        <>
            <GraphqlProductContainerBody
                page={page}
                data={data?.findCategoryById?.products || []}
                totalProducts={data?.findCategoryById?.totalProducts || 0}
                categoryData={categoryData}
                path={path}
                location={location}
                breadCrumbArray={breadCrumbArray}
                skipAddons={skipAddons}
                showMovieUpsell={showMovieUpsell}
                filterData={{ giftBox, localFlorist, options: { lowToHighBtn, highToLowBtn, sellingBtn } }}
                pageNumber={pageCount.current}
                productPageSize={productPageSize}
                loadMoreCount={loadMoreCount.current}
                onLoadMore={onLoadMoreClick}
                loader={false}
                inCollectionBannerData={inCollectionBannerData}
                inCollectionBannerV2Data={inCollectionBannerV2Data}
                leftRail={leftRail}
                isDesktopFacetsV3={isDesktopFacetsV3}
                isCategoryPage={isCategoryPage}
                priceRangeData={priceRangeData}
                variables={variables}
            />
        </>
    );
};

GraphqlProductContainer.propTypes = {
    classes: object.isRequired,
    categoryData: object.isRequired,
    brand: object.isRequired,
    locationType: string.isRequired,
    userSubmittedZip: string.isRequired,
    breadCrumbArray: arrayOf(
        shape({
            title: string.isRequired,
            href: string.isRequired,
        }),
    ).isRequired,
    filterData: shape({
        lowToHighBtn: bool,
        highToLowBtn: bool,
        sellingBtn: bool,
        giftBox: bool,
        localFlorist: bool,
        facets: array,
    }),
    facetsData: array,
    path: string.isRequired,
    page: shape({
        path: string,
    }),
    skipAddons: bool.isRequired,
    showMovieUpsell: oneOfType([
        bool,
        shape({
            icon: shape({
                content_type: string,
                file_size: string,
                filename: string,
                is_dir: bool,
                title: string.isRequired,
                uid: string,
                url: string.isRequired,
            }).isRequired,
            products: arrayOf(
                shape({
                    sku: string.isRequired,
                    sku_description: string.isRequired,
                    sku_price: number.isRequired,
                }).isRequired,
            ).isRequired,
        }),
    ]).isRequired,
    initialProductData: arrayOf(
        shape({
            partNumber: string.isRequired,
            brand: string.isRequired,
            prices: arrayOf(
                shape({
                    __typename: string.isRequired,
                    currency: string.isRequired,
                    type: string.isRequired,
                    value: number.isRequired,
                }).isRequired,
            ).isRequired,
            skuPriceRange: shape({
                sale: arrayOf(
                    shape({
                        value: number.isRequired,
                    }),
                ),
                retail: arrayOf(
                    shape({
                        value: number.isRequired,
                    }),
                ),
            }).isRequired,
            availability: shape({
                deliveryMessage: string.isRequired,
            }).isRequired,
        }).isRequired,
    ),
    location: object.isRequired,
    setFilterZip: func.isRequired,
    setLocation: func.isRequired,
    bestSellersId: string,
    track: func,
    inCollectionBannerData: object.isRequired,
    inCollectionBannerV2Data: object.isRequired,
    isBot: bool.isRequired,
    activeABTests: object.isRequired,
    priceRangeData: arrayOf(
        shape({
            to: string.isRequired,
            name: string.isRequired,
            from: string.isRequired,
        }),
    ),
    zipCodeUnavailableProducts: shape({
        copy: string,
        collection_id: string,
        copy_color: string,
        title: string,
        products_per_row: number,
    }),
    modelNames: arrayOf(shape({
        id: string.isRequired,
        orderBy: string.isRequired,
        name: string.isRequired,
    })),
    leftRail: bool,
    isDesktopFacetsV3: bool.isRequired,
    isGnavEnabled: bool,
    isCategoryPage: bool
};

GraphqlProductContainer.defaultProps = {
    page: { path: '' },
    filterData: {},
    initialProductData: [],
    facetsData: [],
    bestSellersId: '18F-Best Selling Flowers-400215581',
    track: () => {},
    priceRangeData: [],
    zipCodeUnavailableProducts: {},
    modelNames: [],
    leftRail: false,
    isGnavEnabled: false,
    isCategoryPage: false
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    locationType: getLocationType(state),
    userSubmittedZip: getUserSubmittedProductFilterZipcode(state),
    bestSellersId: getBestSellersId(state),
    isBot: getIsBot(state),
    activeABTests: getActiveABTests(state),
    modelNames: getActiveABTest('model_names')(state),
});

const mapDispatchToProps = (dispatch) => ({
    setFilterZip: bindActionCreators(
        setUserSubmittedProductFilterZipcode,
        dispatch,
    ),
    setLocation: bindActionCreators(setLocationType, dispatch),
    track: bindActionCreators(trackEvent, dispatch),
});

const enhance = compose(
    withStyles(styles),
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);

export default enhance(GraphqlProductContainer);
